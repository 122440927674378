<template>
  <div>
    <Row>
      <i-col span="24">
        <Select v-model="chooseCompanyId" @on-change="handleChangeCompanyId">
            <Option v-for="item in childCompanyList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
    </Row>
    <Row class="p-t-5">
      <i-col span="24">
        <Select v-model="chooseAssetId" @on-change="handleChangeAssetId">
            <Option v-for="item in companyAssetList" :value="item.keyId" :key="item.keyId">{{ item.content }}</Option>
        </Select>
      </i-col>
    </Row>
    <Row class="m-t-5 m-b-20">
      <i-col span="31">
        <Select placeholder="全部产品类型" v-model="chooseProductIds" style="width:280px;white-space: normal;word-break: break-all;" multiple clearable>
            <Option v-for="resourceType in assetProducts" :key="resourceType.id" :value="resourceType.id">{{resourceType.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Button type="primary" :loading="beginSearch" style="margin-left: 3px; width: 35px;" icon="ios-search" @click="handleChangeProductIds"></Button>
      </i-col>
    </Row>

    <div style="width:290px;height:200px;" id="businessRadarReport"></div>

    <Row class="text-center" style="margin-top: -20px;">
      <i-col span="8" v-for="(item,index) in businessRadarData" :key="index">
        <p class="text-20">{{item.value}}%</p>
        <p>{{item.dim}}</p>
      </i-col>
    </Row>

    <h2 class="text-center p-t-20">客户画像</h2>
    <Row class="p-t-10 p-b-5">
      <i-col span="24">
        <RadioGroup v-model="chooseGbType" type="button" button-style="solid">
            <Radio v-for="(gbType,index) in gbTypeArray" :key="index" :label="gbType.key">{{gbType.label}}</Radio>
        </RadioGroup>
      </i-col>
    </Row>

    <Table size="small" :columns="portraitColumn" :data="portraitData" :row-class-name="rowClassName"
      @on-row-click="handleClickRow"></Table>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { toMoney } from '@/utils/wnumb_own'

import { listChildrenCompanyAndSelf, listCompanyAssetsAndAll, countContractAmountByUsePriceGb } from '@/api/dw/datav'
import { getAssetBusinessRadar, listProducts } from '@/api/dw/asset'

export default {
  data () {
    return {

      loginCompanyId: this.$store.getters.token.userInfo.companyId,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      industryType: this.$store.getters.token.publisherSetting.industryType,
      assetBoard: this.$store.getters.token.publisherSetting.assetBoard,

      chooseCompanyId: '0',
      chooseAssetId: '0',
      chooseProductIds: [],
      chooseGbType: '',
      chooseTableId: null,
      beginSearch: false,

      childCompanyList: [],
      companyAssetList: [],
      assetProducts: [], // 线路下的产品集合
      businessRadarData: [],
      dataSourcePie: null,
      gbTypeArray: [],

      portraitColumn: [
        { title: '名称', key: 'name' },
        {
          title: '签约额(元)',
          align: 'right',
          width: 120,
          render: (h, params) => {
            return h('span', toMoney(params.row.value))
          }
        },
        {
          title: '占比',
          align: 'right',
          width: 80,
          render: (h, params) => {
            return h('span', params.row.rate + '%')
          }
        }
      ],
      portraitData: []
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.gbTypeArray = [
        { key: 'inter_brand', label: '按平台品牌库' }
      ]

      // 平台行业
      if (this.industryType === 2) {
        this.gbTypeArray.push({ key: 'inter_industry', label: '按平台行业' })
      } else {
        // 如果被设置了允许显示的值， 那么获取相应显示，否则将全部显示
        if (this.assetBoard.includes(1)) {
          this.gbTypeArray.push({ key: 'industry', label: '按用户行业' })
        }

        if (this.assetBoard.includes(2)) {
          this.gbTypeArray.push({ key: 'inter_industry', label: '按平台行业' })
        }
      }

      this.chooseGbType = this.gbTypeArray[0].key
      // 开始加载数据
      this.loadCompanyList()
    },
    loadCompanyList () {
      const postData1 = {
        endDate: this.searchSchedule.endDate,
        loginCompanyId: this.loginCompanyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate
      }
      listChildrenCompanyAndSelf(postData1).then(res => {
        this.childCompanyList = res
        if (res.length > 0) {
          this.chooseCompanyId = res[0].id
          this.loadCompanyAssetList()
        }
      })
    },
    loadCompanyAssetList () {
      const postData2 = {
        publisherId: this.publisherId,
        companyId: this.chooseCompanyId
      }
      listCompanyAssetsAndAll(postData2).then(res => {
        this.companyAssetList = res
        if (res.length > 0) {
          this.chooseAssetId = res[0].keyId
          this.loadAssetProductList()
          this.loadBusinessRadar()
        }
      })
    },
    loadAssetProductList () {
      const postData = {
        endDate: this.searchSchedule.endDate,
        startDate: this.searchSchedule.startDate,
        companyId: this.chooseCompanyId
      }

      listProducts(postData).then(res => {
        this.assetProducts = res
        this.chooseProductIds = []
        this.loadContractAmount()
      })
    },
    loadBusinessRadar () {
      const postData = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        assetId: this.chooseAssetId,
        companyId: this.chooseCompanyId
      }
      getAssetBusinessRadar(postData).then(res => {
        this.businessRadarData = res
        const labels = []
        const ownValue = { value: [], name: '在刊率对比', title: [] }

        res.forEach(element => {
        // 添加标注信息
          labels.push({ name: element.dim, max: 100 })
          ownValue.value.push(element.value)
          ownValue.title.push(element.dim)
        })

        this.initRadarChart(labels, ownValue)
      })
    },
    loadContractAmount () {
      const postData = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        assetId: this.chooseAssetId,
        companyId: this.chooseCompanyId,
        productIds: this.chooseProductIds.length > 0 ? this.chooseProductIds.join(',') : null,
        gbType: this.chooseGbType
      }
      countContractAmountByUsePriceGb(postData).then(res => {
        this.portraitData = res
        this.beginSearch = false
      })
    },
    initRadarChart (labels, ownValue) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('businessRadarReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('businessRadarReport'))

          const option = {
            color: ['#FA8B59', '#4285F4'],
            radar: [
              {
                indicator: labels,
                radius: 70,
                axisName: {
                  fontSize: '10',
                  padding: [0, -6]
                }
              }
            ],
            tooltip: {
              trigger: 'axis',
              position: 'bottom'
            },
            grid: {
              top: 100
            },
            series: [
              {
                type: 'radar',
                tooltip: {
                  trigger: 'item'
                },
                symbolSize: 3,
                areaStyle: {},
                data: [ownValue]
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)
        }
      })
    },
    rowClassName (row, index) {
      return row.id === this.chooseTableId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    handleClickRow (params, index) {
      this.chooseTableId = (this.chooseTableId === params.id) ? null : params.id
      this.$store.commit('set_situation_gbId', this.chooseTableId)
      this.$store.commit('set_situation_beignUpdateMap', new Date())
    },
    handleChangeCompanyId () {
      this.$store.commit('set_situation_companyId', this.chooseCompanyId)
      this.loadCompanyAssetList()

      this.chooseTableId = null
      this.$store.commit('set_situation_gbId', null)
      this.$store.commit('set_situation_beignUpdateMap', new Date())
      this.$store.commit('set_situation_beignUpdateBottomAndRight', new Date())
    },
    handleChangeAssetId () {
      this.$store.commit('set_situation_assetId', this.chooseAssetId)
      this.loadBusinessRadar()
      this.loadAssetProductList()

      this.chooseTableId = null
      this.$store.commit('set_situation_gbId', null)
      this.$store.commit('set_situation_beignUpdateMap', new Date())
      this.$store.commit('set_situation_beignUpdateBottomAndRight', new Date())
    },
    handleChangeProductIds () {
      this.beginSearch = true
      this.$store.commit('set_situation_productIds', this.chooseProductIds)
      this.loadContractAmount()

      this.chooseTableId = null
      this.$store.commit('set_situation_gbId', null)
      this.$store.commit('set_situation_beignUpdateMap', new Date())
      this.$store.commit('set_situation_beignUpdateBottomAndRight', new Date())
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadCompanyList()
      }
    },
    chooseGbType (val) {
      this.handleChangeProductIds()
    }
  }
}
</script>
